<template>
  <div class="table_div">
    <div>
      <el-input placeholder="请输入内容" v-model="search.keywords" class="input-with-select">
        <el-button slot="append" icon="el-icon-search" @click="getList"></el-button>
      </el-input>
    </div>
    <div>
      <el-table
          :data="tableData"
          border
          style="width: 100%;margin:1rem 0;"
          :header-cell-style="{background: '#eef1f6',color: '#606278','text-align': 'center'}"
          :cell-style="{ 'text-align': 'center' }">
        <el-table-column
            v-for="(item, index) in tableHeaders"
            :key="index"
            :prop="item.prop"
            :label="item.label"
            :width="item.width">
        </el-table-column>
        <el-table-column label="操作" fixed="right" width="180">
          <template slot-scope="scope">
            <span class="update_span" v-if="tableHeaders.length<5" @click="operator(scope.row,'select')">选择</span>
            <template v-else>
              <span class="update_span" @click="operator(scope.row,'edit')">编辑</span>
              <span class="delete_span" @click="operator(scope.row,'delete')">删除</span>
            </template>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="pagination_div">
      <el-pagination
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          layout="sizes,prev, pager, next"
          :total="total"
          :page-count="totalPage"
          :page-sizes="[10, 20, 30, 40, 50]"
          :page-size="search.limit"
          :current-page="search.page">
      </el-pagination>
    </div>
  </div>
</template>

<script>
import {
  deleteMould, deleteRSN,
  deleteSendMan,
  selectRSNPage
} from "@/api/shanghaiManifest"
import AddManifestContactDialog from "@/views/ShanghaiManifest/components/contacts/AddManifestContactDialog.vue";

export default {
  components: {AddManifestContactDialog},
  props: {
    tableHeaders: {
      type: Array,
      default: () => []
    },
    typeVal: {
      type: Object,
      default: () => {
        return {
          title: '',
          code: ''
        }
      }
    }
  },
  data() {
    return {
      total: 0,
      totalPage: 0,
      tableData: [],
      type: this.typeVal.code,
      search: {
        page: 1,
        limit: 10,
        status: 0
      },
    }
  },
  methods: {
    init() {
      this.getList();
    },
    getList() {
      let data = {
        ...this.search,
        //查询通知人列表
        id: this.$store.state.user.userId,
        type: this.type,
      };
      selectRSNPage(data).then((response) => {
        this.total = response.data.page.totalCount;
        this.totalPage = response.data.page.totalPage;
        this.tableData = response.data.page.list;
      });
    },
    handleSizeChange(val) {
      this.search.limit = val;
      this.getList();
    },
    handleCurrentChange(val) {
      this.search.page = val;
      this.getList();
    },
    deleteMethods(response) {
      if (response.data.code === 0) {
        this.$message({
          message: '删除成功!',
          type: 'success'
        });
        this.getList();
      } else {
        this.$message({
          message: '删除失败!',
          type: 'warning'
        });
      }
    },
    operator(item, type) {
      if (type === 'edit') {
        this.$emit('edit', item)
      } else if (type === 'select') {
        this.$emit('select', item)
      } else if (type === 'delete') {
        this.handleDeleteRSN(item)
      }
    },
    handleDeleteRSN(item) { //删除收发货模板
      deleteRSN(item).then((response) => {
        this.deleteMethods(response);
      })
    },
  },
  mounted() {
    this.init();
  }
}
</script>

<style lang="stylus" scoped>
.table_div {
  padding: 0;
  height: 100%;
}

.el-input {
  width: 20%;
}

.pagination_div {
  text-align: center;
  margin: 1rem;
}

.update_span {
  cursor: pointer;
  color: blue;
  margin-right: 1.5rem;
}

.delete_span {
  cursor: pointer;
  color: red;
}
</style>
