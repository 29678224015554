// 上海舱单(不知道业务逻辑，先把对应的axios留着，接口调通之后再删除axios4,axios5)
import { request } from "@/utils/request.js";
import { axios5Enum } from "./enum";

const FEST_URL = axios5Enum[process.env.VUE_APP_API_ENV];
// 基于WaitSend组件  Draft组件
// 发送
export function sendHyManifestShHead(id) {
    return request(`/hymanifestshhead/fcw/sendHyManifestShHead`, "post", id, {
        baseURL: FEST_URL,
    });
}

//获取搜索的全部意见
export function querySelectInfo(userId, completionstatus) {
    return request(
        `/hymanifestshhead/fcw/selectInfo?id=${userId}&completionstatus=${completionstatus}`,
        "get",
        "",
        { baseURL: FEST_URL },
    );
}

// 请求展示信息
export function queryShowList(param) {
    return request("/hymanifestshhead/fcw/getHeadList", "get", param, {
        baseURL: FEST_URL,
    });
}

//获取舱单详情
export function getDetailById(param) {
    return request("/hymanifestshhead/fcw/getDetailById", "get", param, {
        baseURL: FEST_URL,
    });
}

// 导出excel文件
export function exportShipExcel(id) {
    return request(`/hymanifestshhead/fcw/exportExcel?id=${id}`, "get", "", {
        responseType: "blob",
        baseURL: FEST_URL,
    });
}

// 基于UpdateDelete组件
// 加载页面数据
export function loadPageData(param) {
    return request("/updatehead/fcw/selectpage", "post", param, {
        baseURL: FEST_URL,
    });
}

// 基于ShanghaiWaitTable组件 基于LookDetailDialog组件   基于DeleteMainfestDialog
// 删除
export function eleDelete(param) {
    return request("/hymanifestshhead/fcw/deleteByIds", "post", param, {
        baseURL: FEST_URL,
    });
}

export function delectbillNo(param) {
    return request("/updatehead/fcw/delectbillno", "get", param, {
        baseURL: FEST_URL,
    });
}

// 遍历货物列表
export function querySelectGoods(param) {
    return request("/hymanifestshgoods/fcw/selectgoods", "get", param, {
        baseURL: FEST_URL,
    });
}

// 基于ShanghaiS组件 // 基于ShanghaiR组件 基于ShanghaiN组件 基于AddManifeatdialog组件
// 模板分页查询
export function selectRSNPage(param) {
    return request("/manifestShContacts/list", "get", param, {
        baseURL: FEST_URL,
    });
}

// 基于ShanghaiRSNtable组件
export function insertOrUpdateRSN(param) {
    return request("/manifestShContacts/insertOrUpdate", "post", param, {
        baseURL: FEST_URL,
    });
}

// 一键收发通，插入
export function insertRSNModule(param) {
    return request("/manifestShContactsModule/save", "post", param, {
        baseURL: FEST_URL,
    });
}

// 一键收发通，列表
export function selectRSNModulePage(param) {
    return request("/manifestShContactsModule/list", "get", param, {
        baseURL: FEST_URL,
    });
}

// 一键收发通，修改
export function updateRSNModule(param) {
    return request("/manifestShContactsModule/update", "post", param, {
        baseURL: FEST_URL,
    });
}

// 一键收发通，删除
export function deleteRSNModule(param) {
    return request("/manifestShContactsModule/delete", "post", param, {
        baseURL: FEST_URL,
    });
}

// 添加
export function addRSN(param) {
    return request("/manifestShContacts/save", "post", param, {
        baseURL: FEST_URL,
    });
}

// 修改
export function updateRSN(param) {
    return request("/manifestShContacts/update", "post", param, {
        baseURL: FEST_URL,
    });
}

// 删除
export function deleteRSN(param) {
    return request("/manifestShContacts/delete", "post", param, {
        baseURL: FEST_URL,
    });
}

// 获取搜索的全部意见
export function mouldSelectList(nickName, type, uid) {
    return request(
        `/hyshcontactdatamould/fcw/selectlist?nickname=${nickName}&type=${type}&id=${uid}`,
        "post",
        {},
        { baseURL: FEST_URL },
    );
}

// 单类模板按名字检索
export function mouldSelectName(nickName, type, uid) {
    return request(
        `/hyshcontactdatamould/fcw/selectnickname?nickname=${nickName}&type=${type}&id=${uid}`,
        "post",
        {},
        { baseURL: FEST_URL },
    );
}

// 模糊检索RSN名字
export function mouldSelectRSNName(nickName, type, uid) {
    return request(
        `/moudle3sh/fcw/selectnicknameall?nickname=${nickName}&type=${type}&id=${uid}`,
        "get",
        {},
        { baseURL: FEST_URL },
    );
}

// 模糊检索RSN数据
export function mouldSelectRSNList(nickName, uid) {
    return request(
        `/moudle3sh/fcw/selectnickname?nickname=${nickName}&id=${uid}`,
        "get",
        {},
        { baseURL: FEST_URL },
    );
}

// 删除发货人
export function deleteSendMan(id) {
    return request(`/hyshcontactdatamould/fcw/deleteRsnMould`, "get", id, {
        baseURL: FEST_URL,
    });
}

//删除收发货模板
export function deleteMould(id) {
    return request("/moudle3sh/fcw/delect/moudlesft", "get", id, {
        baseURL: FEST_URL,
    });
}

// 修改RSN
export function updateRSNInfo(param) {
    return request("/moudle3sh/fcw/updatesft", "post", param, {
        baseURL: FEST_URL,
    });
}

// 修改Methods
export function updateMethodsInfo(param) {
    return request("/hyshcontactdatamould/fcw/updateRsnMould", "post", param, {
        baseURL: FEST_URL,
    });
}

// 新增sft
export function addSFT(param) {
    return request("/hyshcontactdatamould/fcw/savesft", "post", param, {
        baseURL: FEST_URL,
    });
}

// 基于ShanghaiRSN组件
// 查询
export function selectSFT(param) {
    return request("/moudle3sh/fcw/selectsft", "get", param, {
        baseURL: FEST_URL,
    });
}

// 基于Draft组件
// 导出至草稿
export function sendShMould(param) {
    return request(
        "/hymanifestshhead/fcw/sendShMould",
        "post",
        param,
        { baseURL: FEST_URL },
        true,
    );
}

// 基于AddManifeatdialog组件
// 发送报文
export function updateSend(param) {
    return request("/updatehead/fcw/updatesend", "post", param, {
        baseURL: FEST_URL,
    });
}

// 删除货物
export function deleteById(param) {
    return request("/hymanifestshgoods/fcw/delectbyid", "post", param, {
        baseURL: FEST_URL,
    });
}

// 保存
export function saveHead(param) {
    return request("/hymanifestshhead/fcw/saveHead", "post", param, {
        baseURL: FEST_URL,
    });
}

// 存草稿
export function saveDraft(param) {
    return request("/hymanifestshhead/fcw/updateInfo", "post", param, {
        baseURL: FEST_URL,
    });
}

//获取上海舱单模版
export function downloadExcelModule(param) {
    return request("/hymanifestshhead/fcw/download/excel", "get", param, {
        responseType: "blob",
        baseURL: FEST_URL,
    });
}

//上传上海舱单模版
export function importExcelModule(param) {
    return request(
        "/hymanifestshhead/fcw/import/excel",
        "post",
        param,
        { baseURL: FEST_URL },
        true,
    );
}

/**
 * 船代接口测试
 */
//登录
export function agentLogin(param) {
    return request("/hymanifestshhead/fcw/agentLogin", "get", param, {
        baseURL: FEST_URL,
    });
}

//发送
export function agentSend(param) {
    return request("/hymanifestshhead/fcw/agentSend", "get", param, {
        baseURL: FEST_URL,
    });
}

//申报
export function agentDeclare(param) {
    return request("/hymanifestshhead/fcw/agentDeclare", "get", param, {
        baseURL: FEST_URL,
    });
}

//校验
export function agentCheck(param) {
    return request("/hymanifestshhead/fcw/agentCheck", "get", param, {
        baseURL: FEST_URL,
    });
}

//删除
export function agentDelete(param) {
    return request("/hymanifestshhead/fcw/agentDelete", "get", param, {
        baseURL: FEST_URL,
    });
}

//状态同步
export function agentSync(param) {
    return request("/hymanifestshhead/fcw/agentSync", "get", param, {
        baseURL: FEST_URL,
    });
}

// 模糊查询港口数据
export function getAllSelectData(param) {
    return request("/port/fcw/likePort", "get", param, { baseURL: FEST_URL });
}

// 获取所有港口数据
export function getAllPorts(param) {
    return request("/port/fcw/getmessage", "get", param, { baseURL: FEST_URL });
}

// 获取所有国家代码数据
export function getAllCountryCode() {
    return request("/country/fcw/getCountry", "get", "", { baseURL: FEST_URL });
}

// 基于AddGoodsDialog组件
// 修改货物明细
export function updateGoods(param) {
    return request("/hymanifestshgoods/fcw/updategoods", "post", param, {
        baseURL: FEST_URL,
    });
}

// 货物新增
export function addGoods(param) {
    return request("/hymanifestshgoods/fcw/saveGoods", "post", param, {
        baseURL: FEST_URL,
    });
}

//基于UploadManifest2组件 // 基于UploadManifest1组件
// 添加通知,发货,收货人
export function addMan(param) {
    return request(
        "/hymanifestcontactdatamould/fcw/addRsnMould",
        "post",
        param,
        { baseURL: FEST_URL },
    );
}

// 存为一键录入模板
export function mouldInput(param) {
    return request("/hymanifestcontactdatamould/fcw/savesft", "post", param, {
        baseURL: FEST_URL,
    });
}

// 删除
export function delMan(param) {
    return request(
        "/hymanifestcontactdatamould/fcw/deleteRsnMould",
        "get",
        param,
        { baseURL: FEST_URL },
    );
}

// 修改
export function updateMan(param) {
    return request(
        "/hymanifestcontactdatamould/fcw/updateRsnMould",
        "post",
        param,
        { baseURL: FEST_URL },
    );
}

// 显示列表
export function showManList(param) {
    return request(
        "/hymanifestcontactdatamould/fcw/selectRsnMould",
        "get",
        param,
        { baseURL: FEST_URL },
    );
}

// 上传文件
export function uploadManifest(param) {
    return request(
        "/externalManifest/fcw/fcwmanifestData",
        "post",
        param,
        { baseURL: FEST_URL },
        true,
    );
}

// 上传收发通信息
export function uploadManInfo(param) {
    return request("/hy-manifest-contactdata/fcw/savesft", "post", param, {
        baseURL: FEST_URL,
    });
}

// 选用模板
export function selectCurrentMould(param) {
    return request(
        "/hymanifestcontactdatamould/fcw/selectRsnMouldid",
        "get",
        param,
        { baseURL: FEST_URL },
    );
}

// 获取所有nickName作为建议
export function getSuggestionByNickname(param) {
    return request("/moudle3/fcw/selectsft", "get", param, {
        baseURL: FEST_URL,
    });
}

//匹配自定义名称的数据
export function matchSuggestionByNickname(param) {
    return request("/moudle3/fcw/selectnickname", "get", param, {
        baseURL: FEST_URL,
    });
}

// 基于ManifestSend组件
// 改变星
export function changeStarUrgent(param) {
    return request("/externalManifest/fcw/urgent", "get", param, {
        baseURL: FEST_URL,
    });
}

// 船代选择
export function selectShipGeneration(param) {
    return request("/externalManifest/fcw/updateproxy", "get", param, {
        baseURL: FEST_URL,
    });
}

// 导出列表
export function exportLinkExcel(param) {
    return request("/externalManifest/fcw/link/exportexecl", "post", param, {
        baseURL: FEST_URL,
    });
}

// 加载用户舱单列表
export function queryUserManifestList(param) {
    return request("/externalManifest/fcw/link/list", "post", param, {
        baseURL: FEST_URL,
    });
}

// 加急处理
export function handleImmediate(param) {
    return request("/externalManifest/fcw/urgenttwo", "get", param, {
        baseURL: FEST_URL,
    });
}

// 全部提单号用于搜索
export function getSuggestionsByBillNo(param) {
    return request("/externalManifest/fcw/link/listall", "get", param, {
        baseURL: FEST_URL,
    });
}

// 基于DeleteBoxDialog2组件
// 删除箱
export function deleteBox(param) {
    return request("/externalManifest/fcw/delect", "post", param, {
        baseURL: FEST_URL,
    });
}

// 基于AllRSNdialog组件
// 收发通模板删除
export function allManMouldDelete(param) {
    return request("/moudle3/fcw/delect/moudlesft", "get", param, {
        baseURL: FEST_URL,
    });
}

// 更新模板
export function allManMouldUpdate(param) {
    return request("/moudle3/fcw/updatesft", "post", param, {
        baseURL: FEST_URL,
    });
}

// 关闭新建舱单选项卡 uuid存在请求接口
export function deleteByUUID(id) {
    return request(`/hymanifestshgoods/fcw/deleteByUUID?uuid=${id}`, "get", {
        baseURL: FEST_URL,
    });
}

// 修改单条货物列表
export function updategoods(data) {
    return request(`/hymanifestshgoods/fcw/updategoods`, "post", data, {
        baseURL: FEST_URL,
    });
}

// 保存新增货物列表
export function saveGoods(data) {
    return request(`/hymanifestshgoods/fcw/saveGoods`, "post", data, {
        baseURL: FEST_URL,
    });
}

// 发送VGM
export function sendVGMCreate(data) {
    return request(`/hymanifestshhead/fcw/vgmCreate`, "post", data, {
        baseURL: FEST_URL,
    });
}

/**
 * @description 打开聊天室
 * @param {Number} id 舱单id
 */
export function openChatRoom(id) {
    return request(`/hymanifestshhead/createRoom`, "get",{id},{
        baseURL: FEST_URL,
        headers: {
            "Content-Type": "application/x-www-form-urlencoded",
        },
    });
}
